import React, { ChangeEvent, FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';

import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import FractionSelector from '../../../../components/FractionSelector/FractionSelector';
import { HelperTextInputField } from '@thingslog/ui-components';
import { FormulaValidationProps } from '@thingslog/repositories';
import SensorTypeSelector from '../../components/SensorTypeSelector';

const GenericAnalogConfig: FC<GenericAnalogConfigProps> = ({
  index,
  formulaToValidateChangeCallback,
}: GenericAnalogConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ control, getValues }: UseFormReturn<InitialConfigFormFields>): ReactElement => {
        return (
          <>
            {/* working fraction selector component with react-hook-form  */}
            {/* <Controller
              control={control}
              name={`ports.${index}.sensor.scale`}
              render={({
                field,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.sensor.scale`
              >): ReactElement => (
                <FractionSelector
                  className="flex gap-7 items-center justify-center self-start"
                  //disabled={true}
                  fraction={getValues(`ports.${index}.sensor.scale`)}
                  onFractionChange={(_, value): void => {
                    field.onChange(value);
                  }}
                />
              )}
            /> */}

            <Controller
              control={control}
              name={`ports.${index}.sensor.@type`}
              render={({
                field,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.sensor.@type`
              >): ReactElement => (
                <SensorTypeSelector
                  portType={getValues(`ports.${index}.@type`)}
                  deviceModel={getValues('@type')}
                  field={field}
                />
              )}
            />

            <Controller
              control={control}
              name={`ports.${index}.formula`}
              rules={{
                required: {
                  value: true,
                  message: t('sensors_and_network_config_required_field_error'),
                },
              }}
              render={({
                field,
                fieldState,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.formula`
              >): ReactElement => {
                return (
                  <HelperTextInputField
                    {...field}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                      const formula = event.target.value || null;
                      field.onChange(formula);

                      if (formula !== null) {
                        formulaToValidateChangeCallback &&
                          formulaToValidateChangeCallback({
                            formula: formula,
                            sensorIndex: index,
                            formulaType: 'port',
                          });
                      }
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    label={t('sensor_config_port_conversion_formula')}
                    size="small"
                  />
                );
              }}
            />

            <Controller
              control={control}
              name={`ports.${index}.sensor.formula`}
              rules={{
                required: {
                  value: true,
                  message: t('sensors_and_network_config_required_field_error'),
                },
              }}
              render={({
                field,
                fieldState,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.sensor.formula`
              >): ReactElement => {
                return (
                  <HelperTextInputField
                    {...field}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                      const formula = event.target.value || null;
                      field.onChange(formula);

                      if (formula !== null) {
                        formulaToValidateChangeCallback &&
                          formulaToValidateChangeCallback({
                            formula: formula,
                            sensorIndex: index,
                            formulaType: 'sensor',
                          });
                      }
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    label={t('sensor_conversion_formula')}
                    size="small"
                  />
                );
              }}
            />
          </>
        );
      }}
    </ConnectForm>
  );
};

interface GenericAnalogConfigProps {
  index: number;
  formulaToValidateChangeCallback?: (props: FormulaValidationProps) => void;
}

export default GenericAnalogConfig;
