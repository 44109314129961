import React, { FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import UnitsDropdown from '../../../../components/UnitsDropdown/UnitsDropdown';
import { MeasurementUnit } from '@thingslog/repositories';
import { HelperTextInputField } from '@thingslog/ui-components';

const BaseSensorConfig: FC<BaseSensorConfigProps> = ({ index }: BaseSensorConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ control, register, getValues }: UseFormReturn<InitialConfigFormFields>): ReactElement => (
        <>
          <HelperTextInputField
            {...register(`ports.${index}.sensor.precision`)}
            label={t('precision')}
            size="small"
          />
          <HelperTextInputField
            {...register(`ports.${index}.sensor.scale`)}
            label={t('scale')}
            size="small"
          />
          <Controller
            control={control}
            name={`ports.${index}.sensor.units`}
            render={({
              field,
            }: UseControllerReturn<
              InitialConfigFormFields,
              `ports.${number}.sensor.units`
            >): ReactElement => (
              <FormControl>
                <UnitsDropdown
                  allowedUnits={getValues(`ports.${index}`).sensor.allowedUnits ?? []}
                  unit={field.value ?? MeasurementUnit.NO_UNITS}
                  onChange={(unit: MeasurementUnit): void => field.onChange(unit)}
                />
                <FormHelperText> </FormHelperText>
              </FormControl>
            )}
          />

          <HelperTextInputField
            {...register(`ports.${index}.sensor.description`)}
            label={t('sensor_config_description')}
            size="small"
          />

          <HelperTextInputField
            {...register(`ports.${index}.sensor.model`)}
            label={t('sensor_config_model')}
            size="small"
          />

          <HelperTextInputField
            {...register(`ports.${index}.sensor.serialNumber`)}
            label={t('sensor_config_serial_number')}
            size="small"
          />

          <HelperTextInputField
            {...register(`ports.${index}.sensor.manufacturer`)}
            label={t('sensor_config_manufacturer')}
            size="small"
          />

          <HelperTextInputField
            {...register(`ports.${index}.sensor.orderNumber`)}
            label={t('sensor_config_order_number')}
            size="small"
          />

          <Controller
            control={control}
            name={`ports.${index}.sensor.useInSensorsAggregation`}
            render={({
              field,
            }: UseControllerReturn<
              InitialConfigFormFields,
              `ports.${number}.sensor.useInSensorsAggregation`
            >): ReactElement => (
              <FormControl size="small">
                <InputLabel>{t('sensor_config_use_in_sensor_aggregation')}</InputLabel>
                <Select
                  {...field}
                  onChange={(e: SelectChangeEvent): void =>
                    field.onChange(e.target.value === 'true')
                  }
                  value={field.value === true ? 'true' : 'false'}
                  label={t('sensor_config_use_in_sensor_aggregation')}
                >
                  <MenuItem value="true">
                    {t('sensor_config_use_in_sensor_aggregation_true')}
                  </MenuItem>
                  <MenuItem value="false">
                    {t('sensor_config_use_in_sensor_aggregation_false')}
                  </MenuItem>
                </Select>
                <FormHelperText> </FormHelperText>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name={`ports.${index}.sensor.visible`}
            render={({
              field,
            }: UseControllerReturn<
              InitialConfigFormFields,
              `ports.${number}.sensor.visible`
            >): ReactElement => (
              <FormControl size="small">
                <InputLabel>{t('sensor_config_visible')}</InputLabel>
                <Select
                  {...field}
                  onChange={(e: SelectChangeEvent): void =>
                    field.onChange(e.target.value === 'true')
                  }
                  value={field.value === true ? 'true' : 'false'}
                  label={t('sensor_config_visible')}
                >
                  <MenuItem value="true">{t('sensor_config_visible_true')}</MenuItem>
                  <MenuItem value="false">{t('sensor_config_visible_false')}</MenuItem>
                </Select>
                <FormHelperText> </FormHelperText>
              </FormControl>
            )}
          />
        </>
      )}
    </ConnectForm>
  );
};

interface BaseSensorConfigProps {
  index: number;
}

export default BaseSensorConfig;
